import { Rating } from "@mui/material";
import { useEffect } from "react";

function VetInfoCard({ detail }) {
    useEffect(() => {
        console.log(detail);
    }, [])
    return (
        <div className="flex gap-[12px]">
            <div className="flex min-h-[95px] h-[95px] min-w-[95px] w-[95px] overflow-hidden rounded-[8px]">
                <img className="w-full h-full object-cover" src={detail.logo} alt={detail.name} />
            </div>
            <div className="flex flex-col gap-[4px]">
                <h2 className="font-700 sm:text-[14px] sm:leading-[19.6px] text-[12px] leading-[16.8px]">{detail.name}</h2>
                <div className="flex sm:gap-[8px] gap-[4px]">
                    <Rating name='vet-rating' value={detail.rating || 0} precision={0.1} readOnly className="text-peeva-blue" />
                    <p className="font-400 text-[12px] leading-[16.8px] text-peeva-black-110">{detail.reviews} reviews</p>
                </div>
                <p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px] text-peeva-black-110">{detail.phoneNumber}</p>
                <p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px]  text-peeva-black-110">{detail.address1}</p>
            </div>
        </div>
    )
}

export default VetInfoCard;